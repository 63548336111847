/**
 * Returns a message based on the provided error type from an
 * attempted user signup to the API endpoint /v4/users, and some
 * custom types.
 *
 * @param {String} errorType - Error type from backend response
 * @param {Function} trans - Translation function
 * @returns {String} UI message corresponding to the error code
 */
module.exports = function(errorType, trans) {
  let message;
  switch (errorType.toLowerCase()) {
    case "fixerrors":
      message = "Please fix any errors to continue.";
      break;
    case "requiredparammissing":
      message = "Please make sure all fields are filled in.";
      break;
    case "permissiondenied":
      message =
        "Sorry, based on the information you have provided, we couldn't create an account for you.";
      break;
    case "unknown":
    default:
      message = "Something went wrong. Please try again";
      break;
  }
  return trans(message);
};
